import React from 'react'
import { spacing, palette, typography } from '@material-ui/system';
import clsx from 'clsx'
import { Button, Box, Container, Typography, useTheme, } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: props => ({
        ...palette({ ...props, theme }),
        ...spacing({ ...props, theme }),
        ...typography({ ...props, theme }),
    }),
    primaryGradient: {
        background: `${theme.palette.primary.mainGradient}`,
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        textFillColor: 'transparent',
        'backgroundClip': 'text',
    }
}))

const Text = ({ children, className, ...props }) => {
    const classes = useStyles(props)

    return (
        <Typography className={clsx(classes.root, className, {
            [classes.primaryGradient]: props.color === 'primary.mainGradient'
        })} {...props}>
            {children}
        </Typography>
    )
}

export default Text
