import React from 'react'
import { Button, Box, useTheme, Container, makeStyles, Fade } from '@material-ui/core'
import logoSvg from '../images/logo-capital.svg'
import { ScrollToSectionContext } from './Layout'
import { useLocation } from "@reach/router"

const useStyles = makeStyles(theme => ({
  [theme.breakpoints.only('xs')]: {
    logo: {
      maxWidth: 120
    }
  }
}))

const Header = React.forwardRef((props, ref) => {
  const theme = useTheme()
  const classes = useStyles()
  const location = useLocation();

  const showContactButton = location.pathname !== '/confirm/'

  const scrollToSection = React.useContext(ScrollToSectionContext)

  return (
    <Box
      ref={ref}
      py={4}
      display="flex"
      position="absolute"
      top={0}
      width="100%"
      zIndex={theme.zIndex.appBar}
    >
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <img src={logoSvg} className={classes.logo} />

          <Fade in={showContactButton} key={location.pathname}>
            <Button variant="contained" color="primary" onClick={scrollToSection('contact')}>
              S'inscrire
            </Button>
          </Fade>
        </Box>
      </Container>
    </Box>
  )
})

export default Header
