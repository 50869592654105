import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const PRIMARY_GRADIENT = "linear-gradient(to right, #AC9879, #816E52)"

const _theme = createMuiTheme({
    palette: {
        primary: {
            main: `#AC9879`,
            dark: `#816E52`,
            contrastText: "#fff",
            mainGradient: PRIMARY_GRADIENT,
        },
        secondary: {
            main: '#3B5990',
            dark: '#1E2E4B',
            contrastText: "#fff",
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2.9em',
            fontWeight: 600
        },
        h2: {
            fontSize: '2.5rem',
            fontWeight: 600
        },
        h4: {
            fontSize: '1.3rem'
        },
        subtitle1: {
            fontWeight: 300
        }
    },
    overrides: {
        MuiButton: {
            root: {
                letterSpacing: 0.75
            },
            contained: {
                boxShadow: "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)" // 12 elevation
            },
            containedPrimary: {
                background: PRIMARY_GRADIENT
            }
        },
    },
    shape: {
        borderRadius: 0
    }
})

const theme = responsiveFontSizes(_theme);

theme.typography.subtitle1[theme.breakpoints.down('sm')] = {
    fontSize: '.9rem'
}

export default theme