import React from 'react'
import { Box, Paper, Link, Container, Grid } from '@material-ui/core'
import Text from './Text'
import logoSvg from '../images/logo.svg'
import { useMobile } from '../utils/hooks'

const Footer = () => {
    const mobile = useMobile()

    return (
        <Box component="footer" mt={mobile ? 6 : 12}>
            <Paper elevation={12}>
                <Container>
                    <Box py={4}>
                        <Grid container spacing={3} justify="space-between">
                            <Grid item xs={12} sm="auto">
                                <Box>
                                    <img src={logoSvg} />
                                    <Text variant="body1" color="text.secondary">equity crowdfunding stock platform</Text>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm="auto">
                                <Box display="flex" flexDirection="column" justifyContent="flex-start">
                                    <Text variant="body1" color="text.secondary">
                                        Incubit Capital Club SAS<br />
                                        33, rue de la République, Allée B<br />
                                        69002 LYON, France<br />
                                        <Link href="mailto:contact@incubit.fr">contact@incubit.fr</Link>
                                    </Text>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Paper>
        </Box>
    )
}

export default Footer
